import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Loader from "./Loader";

export default function Contact() {

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSucess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)
        try {
            await emailjs.send(
                "service_qy2ob2n",
                "ermita_advosiry_si789ac",
                {
                    name: name,
                    email: email,
                    message:
                        "Nom: " +
                        name +
                        "\nE-mail: " +
                        email +
                        "\n\nSujet: " +
                        subject +
                        "\n\nMessage: \n" +
                        message,
                },
                "vjvK4XYmZqS57OOTC"
            );

            setLoading(false)
            setSucess(true)
        } catch (error) {
            console.log({ error });
            setError(true)
            setLoading(true)
        }
    }

    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-primary text-base font-medium uppercase mb-2">CONTACTEZ-NOUS</h6>
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Vous désirez en savoir plus sur nous ?</h3>

                        <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">Merci de remplir les champs suivants afin que nous puissions vous répondre de façon plus efficace.</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                <form onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input onChange={e => setName(e.target.value)} name="name" id="name" type="text" className="form-input" placeholder="Nom et prénom(s)" />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input onChange={e => setEmail(e.target.value)} name="email" id="email" type="email" className="form-input" placeholder="Adresse mail" />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input onChange={e => setSubject(e.target.value)} name="subject" id="subject" className="form-input" placeholder="Sujet" />
                                        </div>

                                        <div className="mb-5">
                                            <textarea onChange={e => setMessage(e.target.value)} className="form-input textarea h-28" placeholder="Message"></textarea>
                                        </div>

                                        {success && <div className="m-5">
                                            <p className="text-green-600" >Message envoyé avec succès</p>
                                        </div>}
                                    </div>

                                    <button disabled={loading} type="submit" className="btn bg-primary-600 hover:bg-primary-700 border-primary-600 hover:border-primary-700 text-white rounded-md h-11 justify-center flex items-center"> {loading ? 'veuillez patienter...' : 'ENVOYER'}</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Téléphone</h5>
                                        <a href="tel:+33 6 11 26 02 75" className="text-slate-400">+33 6 11 26 02 75</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">E-mail</h5>
                                        <a href="mailto:contact@example.com" className="text-slate-400">contact@ermita-advisory.com</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Adresse</h5>
                                        <p className="text-slate-400 mb-2">	95 BD BERTHIER 75017 PARIS </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
