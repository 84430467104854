import React from "react";
import { Link } from "react-router-dom";

import StrategyImage from "../assets/images/svg/design-thinking.svg";
import departmentImage from "../assets/images/svg/coding.svg";
import launchImage from "../assets/images/svg/office-desk.svg";

export default function Services() {
  const team = [
    {
      title: "Stratégie d'entreprise",
      icon: 'adjust-circle',
      subtext: "Élaboration de plans stratégiques sur mesure pour orienter la croissance, l'expansion et la compétitivité de l'entreprise. Cette stratégie comprend souvent des analyses de marché, des prévisions financières et des recommandations pour atteindre les objectifs à long terme"
    },
    {
      title: "Gestion des ressources humaines",
      icon: 'circuit',
      subtext: "Fourniture de conseils sur le recrutement, la formation, la rétention et la gestion des talents. Cela englobe également l'élaboration de politiques RH, la résolution de conflits et le développement de cultures d'entreprise positives."
    },
    {
      title: "Gestion de projet",
      icon: 'fire',
      subtext: "Aider les entreprises à planifier, exécuter et suivre des projets avec succès. Cela inclut la gestion du calendrier, des ressources, du budget et la résolution des problèmes qui surviennent pendant la réalisation du projet."
    },
    {
      title: 'Finance et comptabilité',
      icon: 'shopping-basket',
      subtext: "Fournir des services financiers tels que la comptabilité, l'analyse financière, la gestion de trésorerie, la planification fiscale et la gestion des coûts pour assurer la stabilité financière et la rentabilité de l'entreprise."
    },
    {
      title: 'Marketing et communication',
      icon: 'flower',
      subtext: "Développer des stratégies de marketing, y compris la gestion de la présence en ligne, la publicité, les médias sociaux, et la communication avec les clients. Cela vise à accroître la visibilité de l'entreprise et à renforcer sa marque."
    },
    {
      title: "Optimisation des opérations",
      icon: 'flip-h',
      subtext: "Analyser les processus opérationnels de l'entreprise pour identifier des améliorations efficaces. Cela peut inclure la réingénierie des processus, l'automatisation des tâches, la gestion de la chaîne d'approvisionnement, et l'optimisation des coûts."
    }
  ]

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-primary text-base font-medium uppercase mb-2">Ermita Advisory,</h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Pour maîtriser et réussir vos <br /> transformations.</h3>
            </div>

            <div>
              <p className="text-slate-500 dark:text-slate-300 max-w-xl">Nous sommes historiquement présents dans le conseil aux secteurs de la Banque, l’Assurance, l’Industrie, la Distribution et le secteur des Services.</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

            {team.map((item, key) => (
              <div key={key} className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-primary/5 text-primary rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-primary dark:text-white dark:hover:text-primary transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-500 mt-3">{item.subtext}</p>

                  {/* <div className="mt-5">
                    <Link to="#" className="btn btn-link hover:text-primary-600 dark:hover:text-primary-600 after:bg-primary-600 dark:text-white transition duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-primary text-base font-medium uppercase mb-2">NOTRE DEMARCHE</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Pour Vous Satisfaire au Mieux</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">La flexibilité et l'adaptabilité sont souvent nécessaires pour s'adapter aux besoins spécifiques de chaque client.</p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={StrategyImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Compréhension des besoins du client</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Dans cette première étape, le cabinet de conseil doit travailler en étroite collaboration avec le client pour comprendre en profondeur ses besoins, ses objectifs, ses défis et son environnement. Cela implique des entretiens, des analyses de documents et des évaluations pour cerner les enjeux spécifiques.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Analyse et recommandations</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Après avoir obtenu une compréhension approfondie des besoins du client, le cabinet de conseil effectue une analyse approfondie. Cela peut inclure la collecte de données, des études de marché, des évaluations financières, des audits opérationnels, etc. Une fois l'analyse terminée, le cabinet de conseil formule des recommandations stratégiques basées sur les résultats de l'analyse. Ces recommandations sont conçues pour aider le client à atteindre ses objectifs et à résoudre les problèmes identifiés.</p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={departmentImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={launchImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Implémentation et suivi</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Après avoir formulé les recommandations, le cabinet de conseil travaille en collaboration avec le client pour mettre en œuvre ces recommandations. Cela peut impliquer des ajustements organisationnels, des changements de processus, des formations, des interventions marketing, etc. Pendant cette phase, il est essentiel de suivre attentivement les progrès, de mesurer les résultats et d'apporter des ajustements si nécessaire pour garantir que les objectifs sont atteints.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}
