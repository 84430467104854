import React, { useState } from "react";
import { Link as Link2 } from "react-router-dom";

import AboutImage from "../assets/images/about.png";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img src={AboutImage} className="rounded-lg shadow-lg relative" alt="" />
              </div>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-primary-600 text-base font-medium uppercase mb-2">Qui sommes-nous ?</h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Conseil et accompagnement des entreprises</h3>

                <p className="text-slate-500 dark:text-slate-500 max-w-2xl mx-auto"> <b className="text-primary" >Ermita Advisory</b> est un cabinet de conseil opérationnel en transformation des organisations. Nous vous proposons de nous immerger dans votre organisation avec les compétences les plus adaptées afin de répondre, de façon pertinente et efficiente, aux enjeux et problématiques identifiés, dans une démarche relationnelle unique. <br /> <br />  Nos consultants vous accompagnent avec une approche immersive de compétences, de connaissances et d'expériences pour atteindre ensemble les objectifs fixés. </p>

                <div className="relative mt-10">
                  <a href="#contact" className="btn bg-primary-600 hover:bg-primary-700 border-primary-600 hover:border-primary-700 text-white rounded-md">Contactez-nous maintenant !</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );

}

